import { ref, reactive, computed, toRefs } from "vue";
import { useFetch } from "@vueuse/core";
import { useFilters } from "@/store/filters";

const {
  facets,
  workingAreas,
  qualifications,
  positions,
  employments,
  workload,
  locations,
  distance,
  searchString,
  crisis,
} = useFilters();

const isLoggedIn = ref(null);
const firstname = ref("");
const lastname = ref("");
const email = ref("");
const subscriptionCreated = ref(false);
const hash = ref("");
const uid = ref(null);
const rules = reactive({
  required: (value) => !!value || "Dieses Feld ist erforderlich",
  isEmail: (value) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      re.test(String(value).toLowerCase()) || "E-Mail-Adresse ist ungültig"
    );
  },
});

const subscribeBody = computed(() => {
  return JSON.stringify({
    locations: locations.value.map((location) => location.id),
    searchString: searchString.value,
    workingAreas: workingAreas.value,
    positions: positions.value,
    qualifications: qualifications.value,
    employments: employments.value,
    workload: `${workload.value[0]}-${workload.value[1]}`,
    distance: distance.value,
    crisis: crisis.value,
    firstname: firstname.value,
    lastname: lastname.value,
    email: email.value,
  });
});

const updateFetchUrl = computed(() => {
  return `${window.location.origin}/api/jobs/subscription/${uid.value}/${hash.value}`;
});

const { execute, error, isFetching } = useFetch(
  `${window.location.origin}/api/jobs/search/subscribe`,
  {
    immediate: false,
    beforeFetch({ options }) {
      options.body = subscribeBody.value;
      return {
        options,
      };
    },
  }
).post();

const updateSubscription = useFetch(updateFetchUrl, {
  immediate: false,
  beforeFetch({ options }) {
    options.body = subscribeBody.value;
    return {
      options,
    };
  },
})
  .put()
  .text();

updateSubscription.onFetchResponse(() => {
  subscriptionCreated.value = !Boolean(error.value);

  if (subscriptionCreated.value) {
    setTimeout(() => {
      subscriptionCreated.value = false;
    }, 3000);
  }
});

const createSubscription = async (event) => {
  const { valid } = await event;

  if (!valid) return;

  await execute();
  subscriptionCreated.value = !Boolean(error.value);
};

const resetSubscriptionCreatedState = () => {
  subscriptionCreated.value = false;
}

const setUser = (user) => {
  firstname.value = user.firstname;
  lastname.value = user.lastname;
  email.value = user.email;
  isLoggedIn.value = Object.keys(user).length > 0;
};

const setHash = (hashState) => {
  hash.value = hashState;
};

const setUid = (uidState) => {
  uid.value = uidState;
};

export const useSubscription = () => {
  return {
    isLoggedIn,
    firstname,
    lastname,
    email,
    subscriptionCreated: computed(() => subscriptionCreated.value),
    error: computed(() => error.value),
    isFetching: computed(() => isFetching.value),
    rules: toRefs(rules),
    createSubscription,
    resetSubscriptionCreatedState,
    updateSubscription,
    setUser,
    setHash,
    setUid,
  };
};
