import { toRefs } from 'vue';
import { reactive, computed } from "vue";

const state = reactive({
  meta: {
    allResultsCount: 0,
    hasResults: false,
    usedResultsPerPage: 0,
    usedPage: 1,
  },
});

const setMeta = (newMeta) => {
    state.meta.allResultsCount = newMeta.allResultsCount;
    state.meta.hasResults = newMeta.allResultsCount > 0
    state.meta.usedResultsPerPage = newMeta.usedResultsPerPage;
    state.meta.usedPage = newMeta.usedPage;
};

export const useMeta = () => {
  return {
    meta: computed(() => state.meta),
    setMeta,
  };
};
