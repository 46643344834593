import { ref, computed } from "vue";

const jobs = ref([]);

export const setJobs = (newJobs) => {
  jobs.value = newJobs;
};

export const useJobs = () => {
  return {
    jobs: computed(() => jobs.value),
    setJobs,
  };
};
