import { reactive, toRefs } from 'vue';

const sortingState = reactive({
  availableSortings: [],
  selectedSorting: ''
})

const updateSortings = (newSortings) => {
  sortingState.availableSortings = newSortings;
}

const setInitialSortings = (newSortings) => {
  sortingState.availableSortings = newSortings;
  sortingState.selectedSorting = newSortings.find(sorting => sorting.selected).name
}

export const useSorting = () => {
  return {
    ...toRefs(sortingState),
    setInitialSortings,
    updateSortings
  }
}
