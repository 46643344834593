/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";

export default createVuetify({
  defaults: {
    global: {
      ripple: false,
    },
    VBtn: {
      rounded: 0,
      density: "compact",
      size: "x-large",
      elevation: 0,
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#0053a1",
          "soz-light": "#ECEAF5",
          "soz-green": "#5a8100",
          secondary: "#5CBBF6",
        },
      },
    },
  },
});
