import { reactive, computed, toRefs } from "vue";

const state = reactive({
  facets: {},
  locations: [],
  searchString: "",
  distance: null,
  crisis: null,
  selectedFilters: {
    workingAreas: [],
    qualifications: [],
    positions: [],
    employments: [],
    workload: [0, 100],
    locations: [],
    distance: null,
    searchString: "",
    crisis: false,
  },
});

const workloadEditedByUser = () => {
  return (
    state.selectedFilters.workload[0] !== 0 ||
    (state.selectedFilters.workload[1] !== 0 &&
      state.selectedFilters.workload[1] !== 100)
  );
};

const hasFiltersSet = computed(() => {
  return Object.entries(state.selectedFilters).some(([key, value]) => {
    if (key === "workload") {
      return workloadEditedByUser();
    }

    if (key === "distance") {
      return value > 0;
    }

    if (key === "crisis") {
      return value;
    }

    return value.length;
  });
});

const additionalFiltersCount = computed(() => {
  return (
    state.selectedFilters.workingAreas.length +
    state.selectedFilters.qualifications.length +
    state.selectedFilters.positions.length +
    state.selectedFilters.employments.length +
    (workloadEditedByUser() ? 1 : 0)
  );
});

const clearFilters = () => {
  state.selectedFilters.workingAreas = [];
  state.selectedFilters.qualifications = [];
  state.selectedFilters.positions = [];
  state.selectedFilters.employments = [];
  state.selectedFilters.workload = [0, 100];
  state.selectedFilters.locations = [];
  state.selectedFilters.distance = null;
  state.selectedFilters.searchString = "";
  state.selectedFilters.crisis = false;
};

const removeFilterValue = (filter, index) => {
  filter.splice(index, 1);
};

const removeWorkloadFilterValue = ()  => {
  state.selectedFilters.workload = [0, 100];
}

const setFacets = (newFacets) => {
  state.facets = newFacets;
};

const setLocations = (newLocations) => {
  state.locations = newLocations;
};

const setSearchString = (newSearchString) => {
  state.searchString = newSearchString;
};

const setDistance = (newDistance) => {
  state.distance = newDistance;
};

const setCrisis = (newCrisis) => {
  state.crisis = newCrisis;
};

const setInitialFilters = () => {
  state.facets.workingAreas?.options.forEach((option) => {
    if (option.selected) {
      state.selectedFilters.workingAreas.push(option.title);
    }
  });

  state.facets.qualifications?.options.forEach((option) => {
    if (option.selected) {
      state.selectedFilters.qualifications.push(option.title);
    }
  });

  state.facets.positions?.options.forEach((option) => {
    if (option.selected) {
      state.selectedFilters.positions.push(option.title);
    }
  });

  state.facets.employments?.options.forEach((option) => {
    if (option.selected) {
      state.selectedFilters.employments.push(option.title);
    }
  });

  if (state.facets.workload?.options[0].title === "0-0") {
    state.selectedFilters.workload = [0, 100];
  } else {
    state.facets.workload.options[0].title.split("-").forEach((item, index) => {
      state.selectedFilters.workload[index] = parseInt(item);
    });
  }

  state.locations?.forEach((option) => {
    state.selectedFilters.locations.push(option.features[0]);
  });

  state.selectedFilters.distance = state.distance;
  state.selectedFilters.searchString = state.searchString;
  state.selectedFilters.crisis = state.crisis.selected;
};

export const useFilters = () => {
  return {
    facets: computed(() => state.facets),
    jobs: computed(() => state.jobs),
    ...toRefs(state.selectedFilters),
    initialCrisis: computed(() => state.crisis),
    workloadEditedByUser,
    hasFiltersSet,
    additionalFiltersCount,
    clearFilters,
    removeFilterValue,
    removeWorkloadFilterValue,
    setFacets,
    setLocations,
    setSearchString,
    setDistance,
    setCrisis,
    setInitialFilters,
  };
};
